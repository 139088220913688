<template>
  <div class="card">
    <div class="card-header">
      <h5 class="m-b-10">{{ $t('sandbox') }}</h5>
    </div>
    <div class="card-block">
      <table class="table">
        <tbody>
          <tr>
            <td class="middle-align">
              <p class="mb-0"><b>{{ $t('sandboxMessaging') }}</b></p>
            </td>
            <td class="middle-align text-right">
              <p class="mb-0 break-word-wrapper">
                <copy-button :text="license.imessage_link" />
                {{ license.imessage_link }}
                <a :href="license.imessage_send_msg_link" target="_blank" class="btn btn-primary btn-sm m-l-10">{{ $t('sandboxComposeMessage') }}</a>
              </p>
            </td>
          </tr>
          <tr>
            <td class="middle-align">
              <p class="mb-0"><b>{{ $t('sandboxRecipientsLimit') }}</b></p>
            </td>
            <td class="middle-align text-right">
              <p class="mb-0">{{ license.sandbox_recipients ? license.sandbox_recipients.length : 0}}/{{license.sandbox_recipients_max_limit}}</p>
            </td>
          </tr>
          <tr v-if="license.sandbox_recipients">
            <td class="middle-align">
              <p class="mb-0"><b>{{ $t('sandboxRecipients') }}</b></p>
            </td>
            <td class="middle-align text-right">
              <div
                class="mb-0 badge badge-primary badge-sm"
                v-for="user in license.sandbox_recipients"
                :key="user">{{ user }}
                  <modal-wrapper>
                    <template v-slot="{ close }">
                      <confirmation-modal
                        :close="close"
                        :content="$t('confirmDeleteSandboxContact')"
                        @confirm="deleteContactSandboxRequest(user, license.license_key, license.secret_api_key)"
                      ></confirmation-modal>
                    </template>
                    <template v-slot:trigger="{ open }">
                      <button
                        class="badge badge-primary feather icon-x close"
                        :disabled="isDisabled"
                        @click.prevent="open"
                      >&nbsp;</button>
                    </template>
                  </modal-wrapper>
              </div>
              <div class="server-error-message content mt-3" v-if="errorMessage.length">
                {{ errorMessage }}
                <button @click.prevent="errorMessage = ''" class="feather icon-x button"></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <add-recipient-form :license="license"></add-recipient-form>
    </div>
  </div>

</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useDeleteSandboxContact } from '@/application/composables/deleteSandboxContact.js'

export default {
  name: 'LicenseDetailSandbox',
  components: {
    'add-recipient-form': defineAsyncComponent(() => import('@/application/components/forms/AddRecipientForm.vue')),
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
    'copy-button': defineAsyncComponent(() => import('@/application/components/elements/CopyButton.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      deleteContactSandboxRequest,
      isDisabled,
      errorMessage,
    } = useDeleteSandboxContact()
    return {
      deleteContactSandboxRequest,
      isDisabled,
      errorMessage,
    }
  },
}
</script>
