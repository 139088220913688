import { ref } from 'vue'
import { useStore } from 'vuex'
import { deleteSandboxUser } from '@/application/services/dashboard.js'
import { useErrorHandler } from '@/application/composables/responseErrorHandler.js'

export function useDeleteSandboxContact() {
  const store = useStore()
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const { responseErrorHandler } = useErrorHandler()
  const deleteContactSandboxRequest = (contact, license_key, secret_api_key) => {
    const data = { contact }
    isDisabled.value = true
    deleteSandboxUser(data, {
      'Loop-Secret-Key': secret_api_key,
    })
      .then(() => {
        store.commit('deleteSandboxContact', {
          contact,
          license_key,
        })
        isDisabled.value = false
      })
      .catch(err => {
        const status = err.response.status
        errorMessage.value = responseErrorHandler(status)
        isDisabled.value = false
      })
  }
  return {
    deleteContactSandboxRequest,
    isDisabled,
    errorMessage,
  }
}
